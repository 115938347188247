import React from 'react'
import { Box, Typography } from '@mui/material'

const Footer1 = () => {
  return (
    <div style={{}}>
        <Box component='footer' sx={{
            display:'flex', 
            height:'130px',
            justifyContent:'center',
            width: '100%',
            position: 'absolute',
            borderRadius: 1,
            bgcolor: '#99ccff',
            '&:hover': {bgcolor: '#cce6ff',}
        }} >
            <Typography variant='h4'>
                コピーライトおおおおおおおおおおおおおおお
            </Typography>
        </Box>
    </div>
  )
}

export default Footer1