import React from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import TvIcon from '@mui/icons-material/Tv';
import RouterIcon from '@mui/icons-material/Router';



export const AppsData = [
  {
    title: "NextCloud",
    icon: <CloudQueueIcon />,
    link: "http://192.168.1.234/",
  },
  {
    title: "KonomiTV",
    icon: <TvIcon />,
    link: "https://192-168-1-234.local.konomi.tv:7000/tv",
  },
  {
    title: "Luci(OpenWrt)",
    icon: <RouterIcon />,
    link: "http://192.168.1.1/",
  },
  {
    title: "Cockpit",
    icon: <SettingsIcon />,
    link: "https://192.168.1.234:9090/system/",
  },
  {
    title: "NextCloud",
    icon: <CloudQueueIcon />,
    link: "http://192.168.1.234/",
  },
  {
    title: "KonomiTV",
    icon: <TvIcon />,
    link: "https://192-168-1-234.local.konomi.tv:7000/tv",
  },
  {
    title: "Luci(OpenWrt)",
    icon: <RouterIcon />,
    link: "http://192.168.1.1/",
  },
  {
    title: "Cockpit",
    icon: <SettingsIcon />,
    link: "https://192.168.1.234:9090/system/",
  },
  {
    title: "NextCloud",
    icon: <CloudQueueIcon />,
    link: "http://192.168.1.234/",
  },
  {
    title: "KonomiTV",
    icon: <TvIcon />,
    link: "https://192-168-1-234.local.konomi.tv:7000/tv",
  },
  
];