import React from 'react'
import styled from '@mui/system/styled';
import { Box } from '@mui/material';
import Header from '../components/Header1';
import '../style/Home.css';
import Notice from '../components/Notice'
import Miniapps from '../components/Miniapps'
import SearchBox from '../components/SearchBox';
import Weather from '../components/Weather';
import Footer1 from '../components/Footer1';


const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  boxShadow: '0px 0px 18px -6px #b4b4b4',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  padding: '7px',
  margin:'10px',
  borderRadius: '30px',
  textAlign: 'center',
}));




const Home = () => {

  return (
    <div style={{backgroundColor :'#c2e9ff',paddingBottom:'20px'}}>
    <Header />
    <div style={{display:'flex',justifyContent:'center'}}>
      <Box sx={{
                margin: '3vh 2vw 5vh 2vw',
                display: 'grid',
                gridTemplateRows: '10vh 25vh 35vh 40vh',
                gridTemplateColumns: 'repeat(5, 19vw)',
              }} >
        <Item sx={{
                    gridRow: '1 / 2',
                    gridColumn: '1 / 6'
                    }}>
        <SearchBox />
        </Item>
                    
        <Item sx={{
                    gridRow: '2 / 3', 
                    gridColumn: '1 / 3'
                    }}>
          <Weather />
        </Item>

        <Item sx={{
                    gridRow: '2 / 3 ',
                    gridColumn: '3 / 5'
                    }}>
          
        </Item>
          
        <Item sx={{
                    gridRow: '2 / 4 ',
                    gridColumn: '5 / 6'
                    }}>
          <Notice />
        </Item>

        <Item sx={{
                    gridRow: '3 / 4 ',
                    gridColumn: '1 / 5'
                    }}>
          <Miniapps />
        </Item>

        <Item sx={{
                    gridRow: '4 / 5 ',
                    gridColumn: '1 / 6'
                    }}>
          
        </Item>

      </Box>
    </div>
    <Footer1 />
    </div>
  )
}

export default Home