import './style/App.css';
import {
  BrowserRouter as Router,Routes,Route,} from "react-router-dom";

import Home from './pages/Home';
import Allapps from './pages/Allapps'
import Setting from './pages/Setting';
import NotFound from './pages/NotFound';
import Cycle from './pages/Cycle';
import Tv from './pages/Tv';





function App() {
  return (
    < div className="app">
    <Router>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/apps" element={<Allapps/>} />
      <Route path="/setting" element={<Setting/>} />
      <Route path="/cycle" element={<Cycle />} />
      <Route path="/tv" element={<Tv />} />
      <Route path="/*" element={<NotFound/>} />
      
      </Routes>
    </Router>
</ div>
  );
}

export default App;
