import React from 'react'

const Notice = () => {
  return (
    <div>
        <h1>Notice</h1>
    </div>
  )
}

export default Notice