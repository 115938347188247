import React from 'react'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import List from '@mui/material/List';
import { Link } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { SidebarData } from "./SidebarData.js";


const DrawerMenu = () => {
  return (
    <>
      <Divider />
      <List>
        {SidebarData.map((value, key) => {
          return (
            <ListItemButton
              sx={{padding: '15px 60px 15px 40px'}}
              key={key}
              id={window.location.pathname === value.link ? "active" : ""}
              className="row"
              component={Link}
              to={value.link}
            >
              <ListItemIcon>{value.icon}</ListItemIcon>
              <div id="title">{value.title}</div>
            </ListItemButton>
          );
        })}
      </List>
    </>
  )
}

export default DrawerMenu