import React from 'react'
import { TextField ,Fab } from '@mui/material';
import { Search } from '@mui/icons-material';

const SearchBox = () => {
  return (
    <div  sx={{
        display:'flex',
        alignItems:'center', 
        justifyContent:'center',}}
        >
        <TextField 
                    id="filled-basic" 
                    label="検索" 
                    variant="filled" 
                    sx={{width:'75%',} } 
                    />
          <Fab 
                    variant="extended" 
                    component="label" 　
                    color="primary" 
                    sx={{
                      margin: '10px 0px 10px 0px',
                      }}>
          <Search 
                    sx={{ 
                      mr: 1 
                      }} />
          検索
          </Fab>
    </div>
  )
}

export default SearchBox