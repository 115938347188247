import React from 'react'
import Header from '../components/Header1'
import Miniapps from '../components/Miniapps'

const Allapps = () => {
  return (
    <>
    <Header />
    <div>
        <Miniapps />
    </div>
    </>
  )
}

export default Allapps