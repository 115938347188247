import { React,useState, } from 'react';
import {AppBar,Box,Toolbar,IconButton,Drawer,Typography} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import GoogleAuthComponent from './GoogleAuthComponentLogin';
import DrawerMenu from './DrawerMenu';
import { useLocation } from "react-router-dom";

const Header = () => {

  const [drawerOpened, setDrawerOpened] = useState(false);

  const location = useLocation();


  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar  sx={{ display: 'flex',  justifyContent: 'space-between'}}>
          <Drawer
            size="lg"
            anchor={'left'}
            open={drawerOpened}
            onClose={() => setDrawerOpened(false)}
          >
            <DrawerMenu />
          </Drawer>
          <IconButton
            onClick={() => setDrawerOpened(true)}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="subtitle">
            {location.pathname}
          </Typography>
          <GoogleAuthComponent />
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default Header