import React from 'react'
import Iframe from 'react-iframe'
import Header from '../components/Header1'
import Footer1 from '../components/Footer1'

const Tv = () => {
  return (
    <div>
        <Header/>
        <Iframe src='https://192-168-1-234.local.konomi.tv:7000/tv'  width='100%' height='800px'/>
        <Footer1/>
    </div>
  )
}

export default Tv