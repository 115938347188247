import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import Button from '@mui/material/Button';


function GoogleAuthComponentLogin() {
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => console.log(codeResponse), // 認証コードを取得
    flow: "auth-code",
    scope: "email profile openid", // scopeはスペース区切り
  });

  return (
    <Button color="inherit" onClick={login}>
      ログイン
    </Button>
  );
}

export default function GoogleAuthComponent() {
  // 認証用のProviderにGCPのクライアントIDを設定
  return (
    <GoogleOAuthProvider clientId={"458309460507-ad2kvekqanr50fi1o6qk3qs4bvsig6q5.apps.googleusercontent.com"}>
      <GoogleAuthComponentLogin />
    </GoogleOAuthProvider>
  );
}