import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SettingsIcon from "@mui/icons-material/Settings";
import TvIcon from '@mui/icons-material/Tv';

export const SidebarData = [
  {
    title: "ホーム",
    icon: <HomeIcon />,
    link: "/",
  },
  {
    title: "すべてのアプリ",
    icon: <AppRegistrationIcon />,
    link: "/apps",
  },
  {
    title: "cycle",
    icon: <SettingsIcon />,
    link: "/cycle",
  },
  {
    title: "設定",
    icon: <SettingsIcon />,
    link: "/setting",
  },
  {
    title: "KonomiTV",
    icon: <TvIcon />,
    link: "/tv",
  },
];