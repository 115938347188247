import React from 'react'
import { TextField,Button } from '@mui/material'
import Header from '../components/Header1'
import axios from 'axios';






const Cycle = () => {


    axios.post("https://api.oneqr.io/apps/app_de4a668566edac9f7b932bb/shops/shp_cf508defd8b9c3dbc59f314/parking-checkout", 
    {rackNo:"111"}
      )
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

  return (
    <div>
        <Header />
        Cycle
        <TextField 
                    id="filled-basic" 
                    label="検索" 
                    variant="filled" 
                    sx={{width:'75%',} } 
                    />
        <Button onClick={axios.post}>検索</Button>
    </div>
  )
}

export default Cycle