import { Box,Button,ListItemIcon, ListItemText, Typography,  } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';
import { AppsData } from './AppData'

const Miniapps = () => {

  return (
    <div>
      <Typography variant='h5'>
        admin-apps
      </Typography>
      <Box
          component="label" 
          variant="contained" 
          sx={{
                margin: '5vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent:'center',
                flexWrap: 'wrap',
                borderRadius: 5,

        }}>

        {AppsData.map((value, key) => {

          return (
            <Button
              sx={{
                  padding: '5vh 4vw 5vh 4vw',
                  margin:'0.5vh 0.5vw 0.5vh 0.5vw',
                  width:'10vw',
                  height:'10vh',
                  textTransform: 'none',
                  display:'flex', 
                  flexFlow:'column',
                  justifyContent:'center',
                  alignItems:'center',
                  boxShadow: 1,
                  borderRadius: 5,
                }}
              key={key}
              className="row"
              component={Link}
              target="_blank"
              to={value.link}
            >
              <ListItemIcon sx={{
                                display:'flex' ,
                                alignItems:'center',
                                justifyContent:'center',
                                fontSize: 80,
                                margin:'1vw',
                                color: 'gray',
                                }}>
                {value.icon}
              </ListItemIcon>
              <ListItemText sx={{color:'dark',listStyle:'none',}}>
              {value.title}
              </ListItemText>
            </Button>
          );
        })}
      </Box>
    </div>
  )
}

export default Miniapps