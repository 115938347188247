import React from 'react'
import Header from '../components/Header1'

const Setting = () => {
  return (
    <>
    <Header />
    <div>
        <br/>Setting
        <br/>Setting<br/>Setting<br/>Setting<br/>Setting<br/>Setting<br/>Setting<br/>Setting<br/>Setting<br/>Setting<br/>Setting<br/>Setting<br/>Setting<br/>Setting<br/>Setting<br/>Setting<br/>Setting
    </div>
    </>
  )
}

export default Setting