import React from 'react'
import Header from '../components/Header1'

const NotFound = () => {
  return (
    <>
    <Header />
    <div>
        <br/>NotFound
        <br/>NotFound<br/>NotFound<br/>NotFound<br/>NotFound<br/>NotFound<br/>NotFound<br/>NotFound<br/>NotFound<br/>NotFound<br/>NotFound<br/>NotFound<br/>NotFound<br/>NotFound<br/>NotFound<br/>NotFound<br/>NotFound
    </div>
    </>
  )
}

export default NotFound